<script>
import Swal from "sweetalert2";
import Vue from "vue";
import vue2Dropzone from "vue2-dropzone";
import Repository from "@/app/repository/repository-factory";
import EventBus from "../../../event-bus";

const MessageRepository = Repository.get("MessageRepository");

export default {
  components: {vueDropzone: vue2Dropzone},
  props: {
    message: {
      type: Object,
      default: null,
    },
    presentationMode: {
      type: String,
      default: 'preview',
      required: false,
    },
  },
  data: function () {
    return {
      dropzoneOptions: {
        url: MessageRepository.getUrlForAddAttachmentToDraftMessage(this.message.uuid),
        createImageThumbnails: false,
        maxFilesize: 5,
        acceptedFiles: 'image/*,application/pdf,.doc,.docx,.xls,.xlsx',
        paramName: "attachment",
        headers: {
          "Authorization": this.getAccessToken(),
          'Cache-Control': null,
          'X-Requested-With': null,
        }
      }
    }
  },
  methods: {
    isDraftMessage(){
      return this.message.status === 'draft';
    },
    isEditMode(){
      return this.presentationMode === 'edit';
    },
    removeAttachment(attachmentId) {
      Swal.fire({
        heightAuto: false,
        title: "Usuwanie załącznika",
        text: "Usuniętego załącznika nie będzie można przywrócić. Czy na pewno powinien być usunięty?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Tak, usuń załącznik!"
      }).then(result => {
        if (result.value) {
          MessageRepository.removeAttachmentFromDraftMessage(this.message.uuid, attachmentId, this.message.version).then(() => {
            EventBus.$emit('MessageAttachmentsChanged', this.messageId);
            Vue.swal({
              icon: "success",
              position: 'center',
              title: "Usunięto załącznik!",
              text: 'Załącznik został poprawnie usunięty.',
              timer: 1000
            });

          }).catch(error => {
            Vue.swal({
              icon: "error",
              position: 'top',
              title: "Wystąpił błąd. Spróbuj jeszcze raz!",
              text: error.response.data.message,
              showConfirmButton: true
            });
          })
        }
      });
    },
    downloadAttachment(attachmentId, attachmentName) {
      const promise = MessageRepository.getAttachment(this.message.uuid, attachmentId);
      return promise.then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachmentName);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      })
    },
    getAccessToken() {
      if (localStorage.getItem('token') !== null) {
        const token = localStorage.getItem('token');
        return "Bearer " + JSON.parse(token).token;
      }
    },
    dropzoneSendingEvent (file, xhr, formData) {
      formData.append('version', this.message.version);
    },
    uploadFileAfterSuccessEvent() {
      this.$refs.myVueDropzone.removeAllFiles();
      EventBus.$emit('MessageAttachmentsChanged', this.messageId);
    },

  },
}
</script>
<template>
  <div class="row">
    <div class="card col-12" v-if="isEditMode()">
      <div class="card-body">
        <h4 class="card-title">Dodaj załączniki</h4>
        <!-- file upload -->
        <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :use-custom-slot="true"
            :options="dropzoneOptions"
            @vdropzone-queue-complete="uploadFileAfterSuccessEvent"
            @vdropzone-sending="dropzoneSendingEvent"
        >
          <div class="dropzone-custom-content">
            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
            <h4>Upuść pliki tutaj lub kliknij, aby przesłać.</h4>
            <h5>Maksymalna wielkość pliku to <strong>5MB</strong><br> Dozwolone rozszerzenie pliku: <strong>pdf, doc, docx, xls, xlsx, jpg, png.</strong></h5>
          </div>
        </vue-dropzone>
      </div>
      <!-- end card-body -->
    </div>
    <template v-if="message.attachments.length > 0">
      <div class="col-12">
        <hr />
        <h4>Wgrane załączniki</h4>
      </div>
      <div class="card col-md-4 col-lg-3 col-6" v-for="(attachment,index) in message.attachments" :key="index">
        <div class="card-body bg-light">
          <p><i class="mdi mdi-attachment"></i> {{attachment.originalFilename}}</p>
        </div>
        <div class="card-footer bg-light">
          <a v-if="isDraftMessage() && isEditMode()" href="javascript:void(0)" class="font-weight-medium" @click="removeAttachment(attachment.attachmentId)">
            <i class="mdi mdi-delete"></i> Usuń
          </a>
          <a href="javascript:void(0)" class="font-weight-medium" @click="downloadAttachment(attachment.attachmentId, attachment.originalFilename)">
            <i class="mdi mdi-download"></i> Pobierz
          </a>
        </div>
      </div>
    </template>
  </div>
</template>
