<template>
  <div>
    <div class="media">
      <div class="media-body mb-0 mt-0">
        <h5 class="font-size-14 mt-1">Od: {{ messageData.author.name }}</h5>
        <small class="text-muted">{{ messageData.author.email }}</small>
      </div>
    </div>
    <div class="media">
      <div class="media-body">
        <span class="font-weight-bold">Do: </span>
        <span v-if="messageData.author.isFoundationEmployee === false">Fundacja</span>
        <span v-if="isDraftMessage && isEditMode">
          <b-badge role="button" class="pt-1 pl-1 pb-1 pr-1" variant="primary" @click="recipientsModal = !recipientsModal">
            <i class="mdi mdi-plus-box" title="Zarządzanie adresatami"></i> Dodaj
          </b-badge>
        </span>
        <span
          v-for="(recipient,index) in messageData.recipients.slice(0, 6)"
          :key="index">
                    {{ recipient.name }},
                  </span>
        <template v-if="messageData.recipients.length >= 7">
          <b-collapse id="collapse" class="mt-1">
                      <span
                        v-for="(recipient,index) in messageData.recipients.slice(7, messageData.recipients.length)"
                        :key="index">
                        {{ recipient.name }},
                      </span>
          </b-collapse>
          i więcej...
          <b-link v-b-toggle.collapse class="m-1" variant="primary">Zobacz wszystkich ({{ messageData.recipients.length }})</b-link>
        </template><br>


        <b-modal size="xl" title="Zarządzanie adresatami" scrollable v-model="recipientsModal">
          <message-recipient-edit :message-id="messageData.uuid" :message-version="messageData.version"/>

          <template v-slot:modal-footer>
            <b-button variant="primary" size="sm" @click="recipientsModal = false">Zamknij
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>

import MessageRecipientEdit from './MessageRecipientEdit';
export default {
  components: {
    MessageRecipientEdit,
  },
  name: 'message-recipient-list',
  props: {
    messageData: {
      type: Object,
    },
    presentationMode: {
      type: String,
      default: 'preview',
      required: false,
    },
  },
  data() {
    return {
      recipientsModal: false,
    }
  },
  computed: {
    isEditMode() {
      return this.presentationMode === 'edit';
    },
    isDraftMessage() {
      return this.messageData.status === 'draft';
    },
  },
  methods: {
  }
}
</script>
