<script>
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";
import EventBus from "../../../event-bus";

const MessageSearchSubaccountRecipient = Repository.get("MessageSearchSubaccountRecipient");
const MessageRepository = Repository.get("MessageRepository");

export default {
  components: {},
  props: {
    messageId: String,
    messageVersion: Number
  },
  data() {
    return {
      title: 'Zarządzanie adresatami wiadomości',
      items: [
        {
          text: 'Wiadomości',
          href: '/',
        },
        {
          text: 'Adresaci',
          active: true,
        },
      ],
      filters: {
        search: '',
        filter: '',
      },
      checkAllSearched: false,
      checkAllAdded: false,
      searchRecipients: [],
      addedRecipients: [],
      selectedToAdd: [],
      selectedToRemove: [],
      isBusy: false,
      filterList: [
        {value: "", text: "Wszystkie"},
        {value: "only_active", text: "Tylko aktywne"},
        {value: "only_inactive", text: "Tylko nieaktywne"},
      ]
    }
  },
  mounted() {
    this.findAddedRecipients();
  },
  watch: {
    'filters.filter': {
      handler: function () {
        this.searchSubaccountRecipients();
      },
      deep: true
    },
    checkAllSearched: {
      handler: function () {
        if (this.checkAllSearched === false) {
          this.selectedToAdd = [];
        }

        if (this.checkAllSearched === true) {
          this.selectedToAdd = this.searchRecipients.map(item => item.subaccountId);
        }
      },
      deep: true
    },
    checkAllAdded: {
      handler: function () {
        if (this.checkAllAdded === false) {
          this.selectedToRemove = [];
        }

        if (this.checkAllAdded === true) {
          this.selectedToRemove = this.addedRecipients.map(item => item.subaccountRecipientId);
        }
      },
      deep: true
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.searchSubaccountRecipients();
    },
    findAddedRecipients() {
      const promise = MessageRepository.findAllRecipientsByMessageId(this.messageId);

      return promise.then((response) => {
        this.checkAllSearched = true;
        this.addedRecipients = response.data;
      }).catch(error => {
        console.log(error);
      })
    },
    addRecipients() {
      let payload = {
        subaccountIds: this.selectedToAdd,
        version: this.messageVersion,
      };

      this.inProgress = true;
      MessageRepository.addRecipientToDraft(this.messageId, payload).then(() => {
        this.findAddedRecipients();
        this.searchRecipients = [];
        this.selectedToAdd = [];
        this.checkAllSearched = false;
        EventBus.$emit('MessageRecipientsChanged', this.messageId);
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Adresaci zostali dodani do wiadomości!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.inProgress = false;
      })
    },
    removeAddedRecipients() {
      let payload = {
        subaccountIds: this.selectedToRemove,
        version: this.messageVersion,
      };

      this.inProgress = true;
      MessageRepository.removeAddedRecipients(this.messageId, payload).then(() => {
        this.findAddedRecipients();
        this.selectedToRemove = [];
        this.checkAllAdded = false;
        EventBus.$emit('MessageRecipientsChanged', this.messageId);
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Adresaci zostali usunięci!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.inProgress = false;
      })
    },
    searchSubaccountRecipients: function () {
      const parameters = '?search=' + this.filters.search + '&filter=' + this.filters.filter;
      const promise = MessageSearchSubaccountRecipient.findRecipient(parameters);

      return promise.then((response) => {

        let newFoundSubaccounts = [];
        let selectedSubaccounts = [];
        response.data.forEach(function (item) {
          selectedSubaccounts.push(item.subaccountId);
          newFoundSubaccounts.push(item);
        });

        this.searchRecipients = newFoundSubaccounts;
        this.selectedToAdd = selectedSubaccounts;

      }).catch(error => {
        console.log(error);
      })
    },
  },
}
</script>

<template>
  <div>
    <!-- Search -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body bg">
            <div class="row">
              <div class="col-sm-7 col-lg-7">
                <b-form inline @submit="onSubmit">
                  <div class="search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.search" :debounce="450"
                                    placeholder="Szukaj..."></b-form-input>
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                  <div class="col-sm-2 col-lg-2 mr-2">
                    <b-form-select v-model="filters.filter" :options="filterList"></b-form-select>
                  </div>
                  <div class="col-sm-2 col-lg-2 ml-5">
                    <b-button variant="primary" v-on:click="searchSubaccountRecipients">Szukaj</b-button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Wyszukani adresaci -->
      <div class="col-lg-6 col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Wyszukani adresaci <span>({{ searchRecipients.length }})</span>
            </h4>
            <div class="row mt-2 mb-2">
              <div class="col-sm-12 col-lg-12">
                <b-button variant="primary" v-on:click="addRecipients" :disabled="this.selectedToAdd.length === 0">
                  Dodaj zaznaczonych</b-button>
              </div>
            </div>
            <hr>
            <b-form-checkbox
              v-model="checkAllSearched"
              class="mb-3"
              name="flavour-4a"
              style="z-index: 0;"
            > Zaznacz / odznacz wszystko
            </b-form-checkbox>

            <b-form-checkbox
              v-for="(subaccount) in searchRecipients"
              v-model="selectedToAdd"
              class="mb-2"
              :key="subaccount.subaccountId"
              :value="subaccount.subaccountId"
              disabled-field="notEnabled"
              name="flavour-4a"
              style="z-index: 0;"
            >
              <i :class="'fas fa-dot-circle ' + [subaccount.isActive ? 'text-success' : 'text-danger', ]"></i>
              {{ subaccount.lastName }} {{ subaccount.firstName }}
              ({{ subaccount.pesel }})

            </b-form-checkbox>
          </div>
        </div>
      </div>

      <!-- Dodani adresaci -->
      <div class="col-lg-6 col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dodani adresaci <span>({{ addedRecipients.length }})</span></h4>

            <div class="row mt-2 mb-2">
              <div class="col-sm-12 col-lg-12">
                <b-button variant="danger" v-on:click="removeAddedRecipients" :disabled="this.selectedToRemove.length === 0">
                Usuń zaznaczone</b-button>
              </div>
            </div>
            <hr>
            <b-form-checkbox v-if="addedRecipients.length > 0"
                             v-model="checkAllAdded"
                             class="mb-3"
                             name="flavour-4a"
                             style="z-index: 0;"
            > Zaznacz / odznacz wszystko
            </b-form-checkbox>

            <b-form-checkbox
              v-for="(recipient) in addedRecipients"
              v-model="selectedToRemove"
              class="mb-3"
              :key="recipient.subaccountRecipientId"
              :value="recipient.subaccountRecipientId"
              disabled-field="notEnabled"
              name="flavour-4a"
              style="z-index: 0;"
            >
              {{ recipient.name }}

            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
